import $ from 'jquery'
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js'
import {Collapse, Dropdown, Modal} from 'bootstrap'
import {Fancybox} from "@fancyapps/ui"
import debounce from 'lodash/debounce.js'
import Inputmask from "inputmask/dist/inputmask.es6.js"
import WOW from 'wow.js'

// import {Alert, Button, Carousel, Collapse, Dropdown, Modal, Popover, ScrollSpy, Tab, Toast, Tooltip} from 'bootstrap'

(() => {
    'use strict'
    window.$ = window.jQuery = $

    function handleResize() {
        document.documentElement.style.setProperty('--header-height', document.querySelector('header').offsetHeight + 'px')
    }

    function handleScroll() {
        if (window.scrollY > 0) {
            document.querySelector('header').classList.add('scrolled')
        } else {
            document.querySelector('header').classList.remove('scrolled')
        }
    }

    const handleDebouncedScroll = debounce(handleScroll, 100, {maxWait: 1000})

    handleResize()
    handleScroll()
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleDebouncedScroll)

    new WOW().init();

    const requestModal = document.getElementById('requestModal')
    requestModal.addEventListener('show.bs.modal', event => {
        const button = event.relatedTarget
        const recipient = button.getAttribute('data-bs-service')
        const modalTitle = requestModal.querySelector('.modal-title')
        const modalInput = requestModal.querySelector('.modal-body input[name="service"]')
        modalTitle.textContent = recipient
        modalInput.value = recipient
    })

    document.querySelectorAll('.js-phone-mask')
        .forEach(item => {
            let im = new Inputmask('+7 999 999-99-99', {"clearIncomplete": true})
            im.mask(item)
        })
})()